window.eventFire = function(el, etype){
  if (el.fireEvent) {
    el.fireEvent('on' + etype);
  } else {
    var evObj = document.createEvent('Events');
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

window.modalShow = function(id){
  var modal = $('#' + id + ' .modal-open-trick')[0]
  window.eventFire(modal, 'click')
}

window.modalHide = function(id){
  var close = $('#' + id + ' .close')[0]
  window.eventFire(close, 'click')
}

/* From calendars/index.html.erb */
function get_calendars(day, month, dir){
  $.get('/calendars/events', {day: day, month: month, dir: dir}, function(data){
    $('#events').html(data)
    update_events_callbacks()
  })
}

function get_calendars_near(near){
  $.get('/calendars/events', {near: near}, function(data){
    $('#events').html(data)
  })
}

function get_today_calendars(day, month){
  get_calendars(day, month, 0)
}

function get_next_calendars(day, month){
  get_calendars(day, month, 1)
}

function get_previous_calendars(day, month){
  get_calendars(day, month, -1)
}
/* */

function update_events_callbacks(){

  /*
  $('.trash-list .item-resume').on('click', function(){
    console.log('ok')
    alert("oh yes")
  })
  */

  $('.spinner-changer').on('click', function(){
    var ni = $(this).data('ni')
    var target = $('#spinner-frame-'+ni).first()
    var active = $('.spinner-active').first()

    if(target.attr('id') != active.attr('id')){

      $('.spinner-active').removeClass('spinner-active')
      target.addClass('spinner-active')

      $('.spinner-changer').removeClass('far')
      $('.spinner-changer').addClass('fas')
      $('.spinner-changer[data-ni='+ni+']').removeClass('fas')
      $('.spinner-changer[data-ni='+ni+']').addClass('far')

      var trans
      if(typeof(t) == "undefined"){
        trans = 0
      } else {
        trans = t
      }

      if(trans==0){
        // Fade effect
        $('.spinner-item').hide()
        target.fadeIn(500)
      } else if(trans==1){
        // Slide effect
        $('.spinner-item').slideUp(500)
        target.slideDown(500)
      } 
    }
  })

  $('.calendar-yesterday').on('click', function(){
    var day = $(this).data('day')
    var month = $(this).data('month')
    get_previous_calendars(day, month)
    scrollTo($('#top-event'))
  })

  $('.calendar-tomorrow').on('click', function(){
    var day = $(this).data('day')
    var month = $(this).data('month')
    get_next_calendars(day, month)
    scrollTo($('#top-event'))
  })

  $('.calendar-highlight-date').on('click', function(){
    window.modalShow('calendar-selector-modal')
  })
}

/* From layouts/old.html.erb */
var mobile_bar
var mobile_bar_top
function update_top(){
  //mobile_bar = $('#nib-mobile-bar')
  //mobile_bar_top = $('#nib-menu-position').offset().top //+ $('#nib-menu-position').height()
}
/* */

/* From admin/memories/_form */
/* and  admin/calendars/_form.html.erb */
var reader = new FileReader();
function preview_input_on_target(input, target) {

  if (input.files && input.files[0]) {
    reader.onload = function(e) {
      $(target).attr('src', e.target.result);
      $('#image-disclaimer').html("Esta imagem só será modificada após salvar o calendário!")
    }

    reader.readAsDataURL(input.files[0]); // convert to base64 string
  } else {
    $(target).attr('src', "");
    $('#image-disclaimer').html("" )
  }
}
/* */

function app_ready(){

  $('.gear-small').css('transition-duration', '5s')
  $('.gear-big').css('transition-duration', '5s')

  window.setTimeout(function(){
    $('.gear-small').css('transform', 'rotate(-360deg)')
    $('.gear-big').css('transform', 'rotate(360deg)')
  }, 2000 )


  $('.trash-list .item-content').on('click', function(){
    console.log('ok')
    alert("this")
  })

  $('#perg-submit').on('click', function(){
    $("#perg-search-form").submit()
  })

  $("#perg-search-form").submit(function (e) {
    e.preventDefault();

    $("#perg-submit").html("Aguarde")
    $('#perg-target').html("Procurando...");

    var title;
    title = $("#perg_search").val();

    $.ajax({
      //url: 'http://152.84.250.11/pergamum/biblioteca/index.php',
      url: '/perg_ajax/' + title,
      type: 'GET',
      dataType: 'json',
      success: function (data) {
        $('#perg-target').html(data['data']);
        $("#perg-submit").html("Buscar")
      }
    });

  });

  $('.canhide-toggler').on('click', function(){
    $(this).parent().parent().parent().find('.canhide').toggle();
  });

  $('.toggle-type').on('change', function(){
    var type, value
    type = $(this).data('type')
    value = $(this).data('value')

    items = $('.result-search[data-'+type+'="' + value + '"]')
    if ($(this).prop('checked')) { 
      items.fadeIn();
    } else {
      items.fadeOut();
    }
  });

  $('.toggle-type-all').on('change', function(){
    var type
    type = $(this).data('type')
    
    items = $('.toggle-type[data-type='+type+']')
    if($(this).prop('checked')){
      items.filter(':not(:checked)').click();
    } else {
      items.filter(':checked').click();
    }
  });


  $('.toggle-type-alternate').on('change', function(){
    var type
    type = $(this).data('type')
    
    items = $('.toggle-type[data-type='+type+']')
    items.click();
  });

  $('.get-collection').on('click', function(){
    var code, collection;
    i = $(this);

    i.toggleClass('collection-open')

    target = $(this).parent().parent().parent().find('.canhide')

    code = i.data('code');
    collection = $('#ccode-'+code);
    collection.html("<div class='loading'><img class='loading-img' src='/loading.gif'>Buscando Conteúdo...</div>");

    if (target.is(":visible")){
      $.post("/collection_ajax/", {code: code}, function(data) {
        p = $('#ccode-'+code)
        p.find('.loading').slideUp();
        p.html(data);
      });
    }
  });

  $('.show-collection-modal').on('click', function(){
    var target = $(this).data('target');
    var modal = $('#collection-modal');
    content = $("#"+target).html();
    $('#collection-modal-body').html(content);

    modal.width('95%')
    modal.css('margin-left', -modal.width()/2)
  
    //modal.modal('show', {'keyboard':true});
  })

	//menus fechados e interacoes de menu principal
	$('#navigation-section > nav.closed > ul').hide();	
 
	$('#navigation-section > nav > h2').on('click', function(){		
		$(this).next().slideToggle();
		$(this).find('i').toggleClass('fa-chevron-down');
		$(this).find('i').toggleClass('fa-chevron-up');
		$(this).parent().toggleClass('closed');
	});	
	/*
  $('#navigation-section > nav > h2 > i:not(.visible-tablet)').parent().css('cursor','pointer');

	URL = document.URL;
	URL = URL.replace('http://', '');
	URL = URL.replace('https://', '');
	URL = URL.substring(URL.indexOf('/'));
  
	$('#navigation-section > nav.closed > ul a').each(function(i){
		link = $(this).attr('href');
		if(URL == link)
			$(this).parents('nav.closed > ul').slideToggle();
	});
  */

	//fim menus fechados e interacoes de menu principal
  
  $('.markdown img').on('click', function(){
    body = $('body')
    clone = $(this).cloneNode(true)
    
    body.append(clone)
    
  })

  $('#calendar_filter_cleaner').on('click', function(){
    $('#day_filter').val('')
    $('#month_filter').val('')

    //$('#calendar_filter_form').find('button[type=submit]').click()
  })

  $('.figure-thumb').on('click', function(){
    source = $(this)
    modal = $('#'+source.data('modal'))
    big = $('#thumb-big-image')
    big.attr('src', source.attr('src'))
  })

  $('.day_period').on('change', function(){
    if(this.value == 0){
      $('.memory_enddate').hide()
    } else {
      $('.memory_enddate').show()
    }

  })

  var menu_target_data = $('#menu-target').data('target')
  if(menu_target_data){
    var menu_target = "#" + menu_target_data + " > ul"
    $(menu_target).show();
  }

  $('#documents-open').on('click', function(){
  //$('#documents-modal').on('show.bs.modal', function(){
    $.get("/documents.json", function(data) {
      var modal_body = $('#documents-modal').find('.modal-body').first()
      modal_body.html('')
      var row
      for(i=0; i< data.length; i++){
        row = $('<div class="row-fluid"></div>')

        item = $(
          "<div class='span1'><input name='doc' class='document-item' data-docref='" + data[i]['id'] + "' type='radio'></div>" +
          "<div class='span1'>" + data[i]['id'] + "</div>" +
          "<div class='span2'>" + data[i]['name'] + "</div>" +
          "<div class='span4'>" + data[i]['filename'] + "</div>"
        )

        
        
        row = $(
          "<div class='row-fluid'></div>"
        )

        item = $(
          "<div class='input-group mb-3'>" +
            "<div class='input-group-prepend'>" +
              "<div class='input-group-text'>" +
                "<input name='doc' class='document-item' type='radio' data-docref='" + data[i]['id'] + "'" +
              "</div>" +
            "</div>" +
            "<span class='input-group-text'>" + data[i]['id'] + "</span>" +
            "<span class='input-group-text'>" + data[i]['name'] + "</span>" +
          "</div>" + 
          "<div class='input-group mb-3'>" +
            "<span class='input-group-text'>" + data[i]['filename'] + "</span>" +
          "</div>"

        )

        row.append(item)
        modal_body.append(row)
      }
      $('#documents-modal').find('.modal-body')

    })
  })
  
  $('#document-insert').on('click', function(){
    var docs_selected = $('.document-item:checked')

    if(docs_selected.length > 0){
      var doc = docs_selected.first()
      var description = $('#memory_description')[0]
      var curStart = description.selectionStart
      var curStop = description.selectionStart
      var textBefore = description.value.substring(0, curStart)
      var textAfter = description.value.substring(curStop, description.value.length)
      var textBetween = " {doc: " + doc.data('docref') + "} "
      description.value = textBefore + textBetween + textAfter
    } 
  })

  /* From trashes/index */

  $('.mark-revert-row').on('click', function(){
    var id = $(this).data('target')
    console.log(id)
    var target = $(".trash-item[data-trash-id='"+id+"'")
    console.log(target)
    target.toggleClass('error')
  })

  $('.trash-itemXXX').on('click', function(){
    console.log($(this).data('trash-id') )
    $.ajax({url: '/admin/trash/' + $(this).data('trash-id'), success: function(result){
      console.log()
    }})
  })

  /* From admin/memories/_form */
  $(".image-preview-input").change(function() {
    var target = $('#' + $(this).data('image-preview-target'))
    console.log($(this).data('image-preview-target'))
    console.log(target)
    preview_input_on_target(this, target);
  });

  $('.icon-select').on('change', function(){
    $('#icon-preview').attr('src', '/libs/tg/timeglider/icons/' + this.value)
    //window.eventFire(document.getElementById('icons-button'), 'click')
    $('#icons-collapse').removeClass('show')
  })

  /* From admin/documents/new */
  $('#document_upload').on('change', function(){
    $('#document_upload_label').html($(this).val().split(/(\\|\/)/g).pop() )
  })

  $('#document-submit').on('click', function(){
    $('#document-loading').show()
    $('#document-new-form').hide()
  })

  /* From admin/calendars/_form.html.erb */
  $(".image-preview-input").change(function() {
    var target = $('#' + $(this).data('image-preview-target'))
    console.log($(this).data('image-preview-target'))
    console.log(target)
    preview_input_on_target(this, target);
  });

  /* From calendars/_drop_calendar.html.erb */

  $('.calendar-shortcut').on('click', function(){
    console.log('shortcut')
    get_today_calendars($(this).data('day'), $(this).data('month') )
    window.modalHide('calendar-selector-modal')
  })

  /* From calendars/index.html.erb */

  //$('#calendar-list-button').on('click', function(){
  $('.calendar-highlight-date').on('click', function(){
    //console.log('ok')
    //$('#calendar-list').slideToggle();
    //$('#calendar-selector').slideToggle();
  })

  $('.calendar-jump').on('click', function(){
    //var day = $(this).data('day')
    //var month = $(this).data('month')

    //var event_id = $(this).data('event')
    //get_calendars_near(event_id)
    //$('#calendar-list').slideToggle();
  })

  /* From calendars/events.html.erb */

  /* From layouts/old.html.erb */
  /*
  $(window).on('scroll', function(){
    tempmenu = $('#tempmenu')
    if($("#responsive-detector").is(":visible")){
      tempmenu.removeClass('nib-mobile-sticky')
      tempmenu.removeClass('nib-sticky-menu')
    } else {
      if(window.pageYOffset >= mobile_bar_top){
        mobile_bar.addClass('nib-mobile-sticky')
        mobile_bar.addClass('nib-sticky-bar')
        tempmenu.addClass('nib-mobile-sticky')
        tempmenu.addClass('nib-sticky-menu')
      } else{ 
        mobile_bar.removeClass('nib-mobile-sticky')
        mobile_bar.removeClass('nib-sticky-bar')
        tempmenu.removeClass('nib-mobile-sticky')
        tempmenu.removeClass('nib-sticky-menu')
      }
    }
  })
  */

  $('.nib-burger').on('click', function(){
    $("#tempmenu").removeClass('d-none')
    $("#tempmenu").slideToggle()
  })

  /* From shared/_left_menu.html.erb */
  $('.nib-menu-item-toggler').on('click', function(){
    icon = $(this).find('i')
    icon.toggleClass('fa-chevron-down')   
    icon.toggleClass('fa-chevron-up')
    $('#' + $(this).data('target')).slideToggle()
    $(this).parent().toggleClass('nib-open')
  })
  
  /* From welcome/_main_banner.html.erb */

  $('#calendar-button-extra').on('click', function(){
    if($(this).hasClass('collapsed')){
      $(this).find('h1').removeClass('fa-chevron-down')
      $(this).find('h1').addClass('fa-angle-double-up')
    } else {
      $(this).find('h1').addClass('fa-chevron-down')
      $(this).find('h1').removeClass('fa-angle-double-up')
    }
  })

  update_top()
  $( window ).resize(function() {update_top()})

  var start_day = $('#start_day').html()
  var start_month = $('#start_month').html()
  //get_today_calendars(start_day, start_month);

  $('#scroll-to-top').on('click', function(){
    window.scrollTo({top: 0, behavior: 'smooth'})
    return(false)
  })

  $('#cod_tipo_obra_group input').on('click', function(){
      total = $('#cod_tipo_obra_group input:first-child')[0]
      if(this == total){ 
        if(total.checked){
          $('#cod_tipo_obra_group .checkbox-wrapper').hide()
        } else {
          $('#cod_tipo_obra_group .checkbox-wrapper').show()
        }
        $('#cod_tipo_obra_group .checkbox-wrapper').first().show()
      }
  })

  $('.search-swap').on('click', function(){
    $('#pergamum-simple-search').slideToggle()
    $('#pergamum-advanced-search').slideToggle()
    console.log('swap')
  })

  $('.pergamum-search-button').on('click', function(){
    var target = $('#acervo-content')
    target.html("<img src='/loading.gif'>")
  })


  $('#calendar-submit').on('click', function(){
    var target = $('#calendar-content')
    target.html("<img src='/loading.gif'>")
  })


  console.log('...app done!')

}

$(document).on('turbolinks:load', app_ready);
