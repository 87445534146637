var number_of_tables = 0
var n_tables = 0;

var tables
var trs
var tds

var append_list = []

function add_table(name, title, csvFile, order){
  $.get(csvFile, function(data){
    var rows = data.split('\n')
    var header = rows[0].split('\t')
    var html = ""

    html+="<div class='table-wrapper' data-order='"+(order)+"'>"

    html+="<h2 class='table-tcg-h2' id='table-"+name+"-h2'><a class='btn btn-outline-primary btn-sm mx-2' data-target='#table-"+name+"' data-toggle='collapse'><span class='fa fa-angle-down'></span> </a>"+title+" </h2>"
    html+="<div class='table-block collapse show'>"
    html+="<table id='table-" + name + "' class='table tcg-table collapse'>"

    html+="<thead><tr>"
    for(var i=0; i < header.length; i++){
      html += "<th>" + header[i] + "</th>"
    }
    html+="</tr></thead>"

    html+="<tbody>"
    for(var j=1; j < rows.length; j++){
      html+="<tr>"
      var cols = rows[j].split('\t')
      if(cols.length < header.length) break
      for(var i=0; i < cols.length; i++){
        html += "<td>" + cols[i] + "</td>"
      }
      html+="</tr>"
    }
    html+="</tbody>"
    html+="</table>"
    html+="</div>"
    html+="</div>"

    var input = $("<input id='tcg-table-"+name+"-checkbox' class='mr-2 mb-2' data-table='"+name+"' type='checkbox' checked>")
    var checkbox = $("<div class='input-group-text'>")
    var label = $("<label>"+name+"</label>")
    checkbox.append(input)
    checkbox.append(label)
    
    append_list[order] = {
      name: name,
      input: input,
      checkbox: checkbox,
      html: html
    }

    n_tables++
    update_loading_progress(n_tables, number_of_tables)
  })
}

function append_all_tables(){
  for(var i=1; i<append_list.length; i++){
    name = append_list[i].name
    input = append_list[i].input
    checkbox = append_list[i].checkbox
    html = append_list[i].html

    $('#tcg-output').append($(html))
    $('#table-list').append(checkbox)      

    input.on('change', function(){
      var thisName = $(this).data('table')
      var thisState = $(this).is(':checked')
      $('#table-'+thisName+'-h2').toggle(thisState)
      $('#table-'+thisName).parent().toggle(thisState)
    })
  }
  append_list = []
}

function load_tables(list, test=false){
  if(test) alert('Tables will be loaded.')
  number_of_tables = list.length
  for(var i=0; i<list.length; i++){
    var name = list[i].name
    var title = list[i].title
    title ||= name
    var link = list[i].link
    add_table(name, title, link, i+1)
  }
}

function update_loading_progress(partial, total){
  load_query_params()
  var number_width = (partial/total)*100.0
  var width = (number_width).toFixed(2) + '%'
  $('#loading-progress').width(width)
  if(number_width > 99.9){
    $('#loading-progress').html('Dados carregados')
    $('#loading-progress').addClass('bg-success')
    $('#search-input-group').addClass('show')
    setTimeout(function(){
      $('#loading-progress-wrapper').slideUp()
    }, 3000)
    setTimeout(function(){
      append_all_tables()
      tables = $('.tcg-table')
      trs = tables.find('tbody tr')
      tds = tables.find('tbody td')
      tables_find()
    }, 100);
  }
}

function tables_find(){ 
  update_query_params()
  tables.find('tbody').removeClass('with-results')
  tables.find('tbody').removeClass('filtered')
  $('.table-tcg-h2').removeClass('highlight')
  $(trs).removeClass('highlight')
  $(tds).removeClass('highlight')

  var ellapsed = Date.now()
  var info = $('#tcg-output-info')
  info.html('')
  var term_el = $('#tcg-search-term')
  var term
  if(term_el) {
    if(term_el.val()) term = term_el.val().trim()
  } else {
    return
  }
  

  if(!term || term=='') {
    info.html('<b>Escreva um termo para filtrar a busca.</b>')
    return;
  }

  var insensitive = $('#tcg-insensitive').is(':checked')
  var hide_unrelated = !$('#tcg-hide-unrelated').is(':checked')

  var td_count=0
  var finds = 0;
  var table_finds = 0;
  var find_in_this_table
  var find_in_this_tr
  var find_in_this_td
  var re
  if(insensitive){
    re = new RegExp(term)
  } else {
    re = new RegExp(term, 'i')
  }

  for(var t=0; t<tables.length;t++){
    var table_trs = $(tables[t]).find('tbody tr')
    find_in_this_table = false
    for(var r=0; r<table_trs.length; r++){
      find_in_this_tr = false
      var tr_tds = $(table_trs[r]).find('td')
      for(var c=0; c<tr_tds.length;c++){
        // inner loop START

        find_in_this_td = false
        var index
        if($('#tcg-regexp').is(':checked')){
          index = tr_tds[c].innerHTML.search(re)
        } else {
          if(insensitive){
            index = tr_tds[c].innerHTML.indexOf(term)
          } else {
            index = tr_tds[c].innerHTML.toLowerCase().indexOf(term.toLowerCase())
          }
        }


        if(index >=0 ){
          find_in_this_table = true
          find_in_this_tr = true
          find_in_this_td = true

          var td = tr_tds[c]
          $(td).addClass('highlight')

          finds++;
        }
        // inner loop END

      } // for TD
      if(find_in_this_tr){
        $(table_trs[r]).addClass('highlight')
      }
    } // for TR
    if(find_in_this_table){
      table_finds++;
      $(tables[t]).addClass('highlight')
      $(tables[t]).find('tbody').addClass('with-results')
      $(tables[t]).find('tbody').toggleClass('filtered', hide_unrelated)

      $("#" + tables[t].id + '-h2').addClass('highlight')
    }
  } // for Table

  ellapsed = Date.now() - ellapsed
  info.html(
    $("<ul><li>Tabelas com resultados: "+table_finds+"</li><li>Termos encontrados: "+finds+"</li><li>Tempo da busca: "+ellapsed/1000+" s</li></ul>")
  )

}

function load_query_params(){
  var queryString = window.location.search
  var x = new URLSearchParams(queryString)

  var query_q = x.get('q')
  if(query_q) $('#tcg-search-term').val(query_q)
  
  var query_case = (x.get('case') || '') == 'true'
  if(query_case) $('#tcg-insensitive').prop('checked', query_case)

  var query_all = (x.get('all') || '') == 'true'
  if(query_all) $('#tcg-hide-unrelated').prop('checked', query_all)

  var query_regexp = (x.get('regexp') || '') == 'true'
  if(query_regexp) $('#tcg-regexp').prop('checked', query_regexp)
}

function update_query_params(){
  var params = []

  //history.replaceState({}, '', newurl);
  //var queryString = window.location.search
  //var x = new URLSearchParams(queryString)

  var query_q = $('#tcg-search-term').val()
  var u = encodeURIComponent(query_q)
  if(query_q) params.push('q='+u)
  
  var query_case = $('#tcg-insensitive').is(':checked') 
  if(query_case) params.push('case='+query_case)

  var query_all = $('#tcg-hide-unrelated').is(':checked')
  if(query_all) params.push('all='+query_all)

  var query_regexp = $('#tcg-regexp').is(':checked')
  if(query_regexp)  params.push('regexp='+query_regexp)

  var base = window.location.protocol + "//" + window.location.host + window.location.pathname
  var all_params = params.join('&')
  var newurl
  if(all_params == '') {
    newurl = base
  } else {
    newurl = base + '?' + all_params
  }
  history.replaceState({}, '', newurl);

}


window.load_tables = load_tables

function tcg_app(){
  var out
  var search_timeout

  $('#tcg-search').on('submit', function(e){
    e.preventDefault()
    tables_find()
  })

  $('#tcg-search-term').on('keydown', function(){
    window.clearTimeout(search_timeout)
    search_timeout = setTimeout(function(){
      tables_find()
    }, 250);
  })

  $('#tcg-hide-unrelated').on('change', function(){
    $('table tbody').toggleClass('filtered')
    tables_find()
  })

  $('#tcg-insensitive').on('change', function(){
    tables_find()
  })
  
  $('#tcg-regexp').on('change', function(){
    tables_find()
  })


  $('#table-checkboxes-all').on('click', function(){
    $('#table-list input[type=checkbox]').prop('checked', true).change()
  })
  
  $('#table-checkboxes-zero').on('click', function(){
    $('#table-list input[type=checkbox]').prop('checked', false).change()
  })

  $('#tcg-show-all-tables').on('click', function(){
    var i = $(this).find('i')
    i.toggleClass('fa-angle-down')
    i.toggleClass('fa-angle-up')
    if(i.hasClass('fa-angle-up')){
      $('.tcg-table').addClass('show')
    } else {
      $('.tcg-table').removeClass('show')
    }
  })

  var t0 = Date.now()

}

$(document).on('turbolinks:load', tcg_app);

