// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

// disabling ActionCable javascripts without uninstall it
//require("channels")

require("jquery")
//require('jquery-migrate');
import 'bootstrap'

//require("packs/jquery-3.0.0.js")
//require("packs/jquery-migrate-3.3.2.min.js")
//require("packs/jquery-migrate-3.3.2.js")
//require("packs/bootstrap.min.js")

//require("packs/bs4/bootstrap.js")


import 'css/site'

import "@fortawesome/fontawesome-free/css/all.css";
//require("packs/bootstrap.min")

//require("packs/jquery-noconflict")
//require("packs/jquery-migrate.min")
//require("packs/jquery.cookie")
//require("packs/bootstrap.min")

//require("packs/mootools-core")
//require("packs/template")
//require("packs/banner-rotativo-home")

require("packs/main")
require("packs/tcg")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



//require("packs/tg/js/json2.js")
//require("packs/tg/js/jquery-1.9.1.min.js")
//require("packs/tg/js/jquery-ui-1.10.3.custom.min.js")
//require("packs/tg/js/underscore-min.js")
//require("packs/tg/js/backbone-min.js")
//require("packs/tg/js/jquery.tmpl.js")
//require("packs/tg/js/ba-debug.min.js")
//require("packs/tg/js/ba-tinyPubSub.js")
//require("packs/tg/js/jquery.mousewheel.js")
//require("packs/tg/js/jquery.ui.ipad.js")
//require("packs/tg/js/globalize.js")
//require("packs/tg/timeglider/TG_Date.js")
//require("packs/tg/timeglider/TG_Org.js")
//require("packs/tg/timeglider/TG_Timeline.js")
//require("packs/tg/timeglider/TG_TimelineView.js")
//require("packs/tg/timeglider/TG_Mediator.js")
//require("packs/tg/timeglider/timeglider.timeline.widget.js")
//require("packs/tg/timeglider/timeglider.datepicker.js")
//require("packs/tg/js/jquery.jscrollpane.min.js")
//require("packs/tg/tg_source.js")
//

//= require_self
//= require_tree .
